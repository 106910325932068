import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

interface topDivProps {
    clientHeight:string
  }
const TopDiv = styled.div<topDivProps>`
    width:100%;
    min-height:${(props) => props.clientHeight+"px"};
    @media (max-width: 768px) {
      min-height:${(props) => props.clientHeight+"px"};
    }
    @media (max-width: 375px) {
      min-height:${(props) => props.clientHeight+"px"};
    }
`
const HeaderArea = styled.header`
    position: fixed;
    width:100%;
    z-index:99;
    border-bottom:1px solid #a9a9a9;/*dark gray*/
    background-color:#fff;
    @media (max-width: 768px) {
        display: -webkit-flex; /* Safari */
        display: flex;
        -webkit-justify-content: center; /* Safari */
        justify-content:center;
    }
    @media (max-width: 375px) {
        height:50px;
    }
    @media (prefers-color-scheme: dark) {
        color: #FFF;
        background: #202020;
    }
    @media (prefers-color-scheme: light) {
        color: #000;
        background: #FFF;
    }
`
const LogoAreaWrapper = styled.div`
    width:100%;
    border-bottom:1px solid #000000;
    padding-top:10px;
    padding-bottom:10px;
    @media (max-width: 768px) {
        border-bottom:0px;
    }
    @media (max-width: 375px) {
        padding:0;
    }
`
const LogoArea = styled.div`
    max-width:2000px;
    margin-left:auto;
    margin-right:auto;
    @media (max-width: 768px) {
        border-bottom:0px;
    }
    @media (max-width: 375px) {
        padding-top:5px;
    }
`
const LogoTop = styled.img`
    width:140px;
    @media (max-width: 768px) {
        width:110px;
    }
    @media (max-width: 375px) {
        width:90px;
    }
`
const HeaderSpacer = styled.div`
    width:100%;
`
const HamburgerMenu  = styled.div`
    @media (min-width: 768px) {
        height:0px;
    }
    @media (max-width: 768px) {
        width:80px;
        cursor:pointer;
        position:relative;
    }
`
const HamburgerLineWrapper  = styled.div`
    // @media (max-width: 768px) {
    //     position:absolute;
    // }
`
interface hamburgerProps {
    openHeader : any;
}
const HamburgerLine1 = styled.div<hamburgerProps>`
    z-index:200;
    @media (max-width: 768px) {
        border-bottom:4px solid gray;
        width:30px;
        height:0px;
        position:absolute;
        top:${(props) => props.openHeader?"30px":"15px"};
        margin-right:0;
        transition: all 0.3s 0s ease;
        transform:rotate(${(props) => props.openHeader?"-45deg":"0deg"});
    }
    @media (max-width: 375px) {
        border-bottom:4px solid gray;
        width:35px;
        height:0px;
        position:absolute;
        top:${(props) => props.openHeader?"25px":"10px"};
        
        transition: all 0.3s 0s ease;
        transform:rotate(${(props) => props.openHeader?"-45deg":"0deg"});
    }
`
const HamburgerLine2 = styled.div<hamburgerProps>`
    z-index:200;
    @media (max-width: 768px) {   
        border-bottom:4px solid gray;
        width:35px;
        height:0px;
        position:absolute;
        top:30px;
        left:${(props) => props.openHeader?"-50px":"0px"};
        opacity:${(props) => props.openHeader?"0":"1"};
        
        transition: all 0.3s 0s ease;
    }
    @media (max-width: 375px) {
        border-bottom:4px solid gray;
        width:35px;
        height:0px;
        position:absolute;
        top:25px;
        left:${(props) => props.openHeader?"-50px":"0px"};
        opacity:${(props) => props.openHeader?"0":"1"};
    
        transition: all 0.3s 0s ease;
    }
`
const HamburgerLine3 = styled.div<hamburgerProps>`
    z-index:200;
    @media (max-width: 768px) {   
        border-bottom:4px solid gray;
        width:35px;
        height:0px;
        position:absolute;
        top:${(props) => props.openHeader?"30px":"45px"};
    
        transition: all 0.3s 0s ease;
        transform:rotate(${(props) => props.openHeader?"45deg":"0deg"});
    }
    @media (max-width: 375px) {
        border-bottom:4px solid gray;
        width:35px;
        height:0px;
        position:absolute;
        top:${(props) => props.openHeader?"25px":"40px"};
    
        transition: all 0.3s 0s ease;
        transform:rotate(${(props) => props.openHeader?"45deg":"0deg"});
    }
`
const NavBar = styled.nav`
    width:100%;
    max-width:2000px;
    margin-left:auto;
    margin-right:auto;
    @media (max-width: 768px) {
        width:0%;
        margin:0;
    }
    @media (max-width: 375px) {
        height:40px;   
    }
`
const Ul = styled.ul<hamburgerProps>`
    display: -webkit-flex; /* Safari */
    display: flex;
    list-style:none;
    padding-left:30px;
    margin:0;
    max-width:1300px;
    @media (max-width: 768px) {
        transition: all 0.2s 0s ease;
        display:inline-block;
        position:absolute;
        right:${(props) => props.openHeader?"0px":"-1000px"};
        top:57px;
        background:gray;
        color:white;
        font-weight:bold;
        width:100%;
        height:100vh;
        opacity:0.9;
        z-index:99;
    }
    @media (max-width: 375px) {
        top:50px;
    }
`
const Li = styled.li`
    width:100%;
    padding-top:20px;
    padding-bottom:20px;
    text-align:left;
    cursor:pointer;
    position:relative;
    @media (min-width: 768px) {
        &::after{
            position: absolute;
            display: block;
            content: " ";
            width: 80%;
            height: 3px;
            background: red;
            bottom:15px;
            transform: scale(0, 1);   
            transform-origin: left top;   
        }
        &:hover::after {
            transform: scale(1, 1);   
            transition: transform 0.3s; 
        }
    }
    @media (max-width: 768px) {
        &:hover {
            background:#a9a9a9;
        }
    }
`
const ListLink = styled(Link)`
    text-decoration:none;
    color:#000000;
    width:100%;
    position:relative;
    @media (min-width: 768px) {
        &::after{
            position: absolute;
            display: block;
            left: 0%;
            content: " ";
            width: 60%;
            height: 3px;
            background: red;
            bottom:15px;               
            transform: scale(0, 1);
            transform-origin: left top;   
        }
        &:hover::after {
            transform: scale(1, 1);   
            transition: transform 0.3s; 
        }
    }
    @media (max-width: 768px) {
        color:white;
    }
    @media (prefers-color-scheme: dark) {
        color: #FFF;
    }
    @media (prefers-color-scheme: light) {
        color: #000;
    }
`

const manual_scroll = function(to : any){
    var smoothScrollFeature = 'scrollBehavior' in document.documentElement.style;
    // var articles : any = document.querySelectorAll("ul#content > li"), i: number;
    // if (to == 'elem') to = articles[id].offsetTop;
    var i : number = parseInt(String(window.pageYOffset));
    if ( i !== to ) {
        if (!smoothScrollFeature) {
            to = parseInt(to);
            if (i < to) {
                var int = setInterval(function() {
                    if (i > (to-20)) i += 1;
                    else if (i > (to-40)) i += 3;
                    else if (i > (to-80)) i += 8;
                    else if (i > (to-120)) i += 12;
                    else if (i > (to-160)) i += 18;
                    else if (i > (to-200)) i += 24;
                    else if (i > (to-250)) i += 30;
                    else if (i > (to-300)) i += 40;
                    else i += 60;
                    window.scroll(0, i);
                    if (i >= to) clearInterval(int);
                }, 10);
            }
            else {
                // eslint-disable-next-line
                var int = setInterval(function() {
                    if (i < (to+20)) i -= 1;
                    else if (i < (to+40)) i -= 3;
                    else if (i < (to+80)) i -= 8;
                    else if (i < (to+120)) i -= 12;
                    else if (i < (to+160)) i -= 18;
                    else if (i < (to+200)) i -= 24;
                    else if (i < (to+250)) i -= 30;
                    else if (i < (to+300)) i -= 40;
                    else i -= 60;
                    window.scroll(0, i);
                    if (i <= to) clearInterval(int);
                }, 10);
            }
        }
        else {
            window.scroll({
                top: to,
                left: 0,
                behavior: 'smooth'
            });
        }
    }
};
const using_funcScroll = (coordinate:any) => {
    window.scrollTo({
        top: coordinate,
        left: 0,
        behavior: 'smooth'});
}               

export const Header = () => {
    const [openHeader,setOpenHeader] = useState<boolean>(false);
    const [clientHeight,setClientHeight] = useState<string>("130");
    const currentLocation : any = useLocation().pathname;
    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    } 
    // eslint-disable-next-line
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    // eslint-disable-next-line
    useEffect(() => {
        let client_h : any = document.getElementById("suiteup_header")?.clientHeight;
        setClientHeight(client_h);
        const onResize = () => {
            let client_h : any = document.getElementById("suiteup_header")?.clientHeight;
            setClientHeight(client_h);
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    });
    
    const expansionMenu = () => {
        if(!openHeader){
            setOpenHeader(true);
        }else{
            setOpenHeader(false);
        }
    }
    const agent = window.navigator.userAgent.toLowerCase();
    const windowScroller = agent.indexOf("safari") !== -1 || agent.indexOf("edg") !== -1 || agent.indexOf("edge") !== -1?manual_scroll:using_funcScroll;
    const scrollController = (page:string) => {
        let client_h : any = document.getElementById("suiteup_header")?.clientHeight;
        switch(page){
            case "home":
                windowScroller(0);
            break
            case "product":
                const element1 : any = document.getElementById("product_section");
                // console.log(element1.offsetTop);
                windowScroller(element1.offsetTop-client_h);
            break;
            case "service":
                const element2 : any = document.getElementById("service_section");
                // console.log(element2.offsetTop);
                windowScroller(element2.offsetTop-client_h);
            break;
            case "case":
                const element3 : any = document.getElementById("instance_section");
                // console.log(element3.offsetTop);
                windowScroller(element3.offsetTop-client_h);
            break;
            case "news":
                const element4 : any = document.getElementById("news_section");
                // console.log(element4.offsetTop);
                windowScroller(element4.offsetTop-client_h);
            break;
            case "inquiry":
                const element5 : any = document.getElementById("inquiry_section");
                // console.log(element5.offsetTop);
                windowScroller(element5.offsetTop-client_h);
            break;
        }
    }
    return(
        <>
        <HeaderArea id="suiteup_header">
            <LogoAreaWrapper>
                <LogoArea>
                    <a href="./index.html"><LogoTop src={`${process.env.PUBLIC_URL}/home/suiteup_logo.webp`}/></a>
                </LogoArea>
            </LogoAreaWrapper>
            <HeaderSpacer/>
            <HamburgerMenu onClick={expansionMenu}>
                <HamburgerLineWrapper>
                    <HamburgerLine1 openHeader={openHeader}>　</HamburgerLine1>
                    <HamburgerLine2 openHeader={openHeader}>　</HamburgerLine2>
                    <HamburgerLine3 openHeader={openHeader}>　</HamburgerLine3>
                </HamburgerLineWrapper>
            </HamburgerMenu>
            <NavBar>
                <Ul openHeader={openHeader}>
                    {
                        currentLocation !== "/"?
                        <>
                        <ListLink to="/" state={{ id: "home" }} onClick={() => {expansionMenu();}}><Li>ホーム</Li></ListLink>
                        <ListLink to="/" state={{ id: "service" }} onClick={() => {setOpenHeader(false);}}><Li>サービス</Li></ListLink>
                        <ListLink to="/" state={{ id: "product" }} onClick={() => {setOpenHeader(false);}}><Li>プロダクト</Li></ListLink>
                        <ListLink to="/" state={{ id: "case" }} onClick={() => {setOpenHeader(false);}}><Li>事例紹介</Li></ListLink>
                        <ListLink to="/" state={{ id: "news" }} onClick={() => {setOpenHeader(false);}}><Li>What's New</Li></ListLink>
                        <ListLink to="/" state={{ id: "inquiry" }} onClick={() => {setOpenHeader(false);}}><Li>お問合せ</Li></ListLink>
                        <ListLink to="/recruit" onClick={() => {setOpenHeader(false);}}><Li>採用情報</Li></ListLink>
                        <ListLink to="/companyoverview" onClick={() => {setOpenHeader(false);}}><Li>会社概要</Li></ListLink></>:
                        <>
                        <Li onClick={() => {expansionMenu();scrollController("home");}}>ホーム</Li>
                        <Li onClick={() => {setOpenHeader(false);scrollController("service");}}>サービス</Li>
                        <Li onClick={() => {setOpenHeader(false);scrollController("product");}}>プロダクト</Li>
                        <Li onClick={() => {setOpenHeader(false);scrollController("case");}}>事例紹介</Li>
                        <Li onClick={() => {setOpenHeader(false);scrollController("news");}}>What's New</Li>
                        <Li onClick={() => {setOpenHeader(false);scrollController("inquiry");}}>お問合せ</Li>
                        <ListLink to="/recruit" onClick={() => {setOpenHeader(false);}}><Li>採用情報</Li></ListLink>
                        <ListLink to="/companyoverview" onClick={() => {setOpenHeader(false);}}><Li>会社概要</Li></ListLink>
                        </>
                    }
                </Ul>
            </NavBar>
        </HeaderArea>
        <TopDiv id="home_section" clientHeight={clientHeight}></TopDiv>
        </>
    )
}