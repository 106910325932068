import styled from "styled-components";
import {Link} from  "react-router-dom";

const FooterArea = styled.footer`
    width:100%;
`
const FooterLinkArea = styled.div`
    width:100%;
    min-height:200px;
    background:#d3d3d3;
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
`
const LinkUl = styled.ul`
    list-style:none;
`
const LinkLiTitle = styled.ul`
    font-size:18px;
    font-weight:bold;
    margin-top:20px;
    margin-bottom:20px;
    padding:0;
    color:#000000;
    @media (max-width: 768px) {
        font-size:14px;
    }
    @media (max-width: 375px) {
        font-size:13px;
    }
`
const LinkLi = styled.ul`
    margin-top:20px;
    margin-bottom:20px;
    padding:0;
`
const FooterLogoArea = styled.div`
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
`
const FooterLogoImgWrapper = styled.div`
    margin-top:auto;
    margin-bottom:auto;
` 
const FooterLogoImg = styled.img`
    width:140px;
    @media (max-width: 768px) {
        width:110px;
    }
    @media (max-width: 375px) {
        width:90px;
    }
`
const FooterLogoText = styled.span`
    margin-top:auto;
    margin-bottom:auto;
    margin-left:10px;
    @media (max-width: 375px) {
        text-align:right;
    }
`
const ListLink = styled(Link)`
    text-decoration:none;
    color:#000000;
`
export const Footer = () => {

    return(
        <FooterArea>
            <FooterLinkArea>
                <LinkUl>
                    <LinkLiTitle>会社情報</LinkLiTitle>
                    <LinkLi><ListLink to="/companyoverview">会社概要</ListLink></LinkLi>
                    <LinkLi><ListLink to="/recruit">採用情報</ListLink></LinkLi>
                </LinkUl>
                <LinkUl>
                    <LinkLiTitle>プロダクト</LinkLiTitle>
                    <LinkLi><ListLink to="/product3581" onClick={() => window.scrollTo(0, 0)}>FSLink™️</ListLink></LinkLi>
                    <LinkLi><ListLink to="/product3836" onClick={() => window.scrollTo(0, 0)}>SU Approval Workflow</ListLink></LinkLi>
                    <LinkLi><ListLink to="/product3837" onClick={() => window.scrollTo(0, 0)}>SU 月締請求書</ListLink></LinkLi>
                </LinkUl>
                <LinkUl>
                    <LinkLiTitle>　</LinkLiTitle>
                    <LinkLi>　</LinkLi>
                    <LinkLi>　</LinkLi>
                </LinkUl>
            </FooterLinkArea>
            <FooterLogoArea>
                <FooterLogoImgWrapper>
                    <FooterLogoImg src={`${process.env.PUBLIC_URL}/home/suiteup_logo.webp`}/>
                </FooterLogoImgWrapper>
                <FooterLogoText>Copyright © SuiteUP Inc. All Rights Reserved.</FooterLogoText>
            </FooterLogoArea>
        </FooterArea>
    )
}