import styled from "styled-components";
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom";
import React from "react";
import { useEffect , useState } from "react";
import ReactGA from "react-ga4";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics 測定 ID を入力して設定
    ReactGA.initialize("G-S76879P0PF");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;

const GroundWrapper = styled.div`
    width:100%;
    height:100%;
    max-width:1200px;
    margin-left:auto;
    margin-right:auto;
`
const HistoryArea = styled.div`
    height:70px;
    @media (max-width: 768px) {
        height:50px;
    }
    @media (max-width: 375px) {
        height:30px;
    }
`
const History = styled(Link)`
    text-decoration:none;
    @media (prefers-color-scheme: dark) {
        color: #FFF;
    }
    @media (prefers-color-scheme: light) {
        color: #000;
    }
`
const NewsArrange = styled.div`
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
    @media (max-width: 375px) {
        flex-flow: column;
    }
`
const SnsWrapper1 = styled.div`
    display: -webkit-flex; /* Safari */
    display:flex;
    flex-flow: column;
    @media (max-width: 375px) {
        display:none;
    }
`
const SnslogoLink1 = styled.a`
    cursor:pointer;
`
const Snslogo1 = styled.img`
    width:50px;
    @media (max-width: 768px) {
        width:40px;
    }
    @media (prefers-color-scheme: dark) {
        background-color: #FFF;
    }
`
const SnsSpan1 = styled.span`
    height:30px;
    @media (max-width: 768px) {
        height:20px;
    }
`
const SnsWrapper2 = styled.div`
    display:none;
    @media (max-width: 375px) {
        display: -webkit-flex; /* Safari */
        display:flex;
        flex-flow: row;
        -webkit-justify-content: space-around; /* Safari */
        justify-content:space-around;
    }
`
const SnslogoLink2 = styled.a`
    cursor:pointer;
    
`
const Snslogo2 = styled.img`
    width:50px;
    @media (max-width: 768px) {
        width:45px;
    }
    @media (max-width: 375px) {
        width:35px;
    }
`
const ArticleWrapper = styled.div`
    max-width:1000px;
    width:80%;
    margin-left:auto;
    margin-right:auto;
`
const ArticleTitle = styled.p`
    font-weight:bold;
    font-size:40px;
    margin:0;
    @media (max-width: 768px) {
        font-size:30px;
    }
    @media (max-width: 375px) {
        font-size:20px;
    }
`
const UpdateDate = styled.p`
    margin-top:0px;
`
const ArticleDetail = styled.p`

`
const ArticleImgWrapper = styled.div`
    text-align:center;
`
interface ArticleImgProps {
    imgState:string
}
const ArticleImg = styled.img<ArticleImgProps>`
    max-width:1200px;
    display:${(props) => props.imgState};
`
const TransitionArea = styled.div`
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: space-between;; /* Safari */
    justify-content:space-between;
    margin-top:20px;
    margin-bottom:20px;
`
const Back = styled(Link)`
    text-decoration:none;
    @media (prefers-color-scheme: dark) {
        color: #FFF;
    }
    @media (prefers-color-scheme: light) {
        color: #000;
    }
`
const Forward = styled(Link)`
    text-decoration:none;
    @media (prefers-color-scheme: dark) {
        color: #FFF;
    }
    @media (prefers-color-scheme: light) {
        color: #000;
    }
`

export const News = React.memo(() => {
    const [imgState,setImgState] = useState<string>("");
    const sampleLocation : any = useLocation().pathname;
    const currentLink = encodeURIComponent(window.location.href);
    const shareTextTwitter = encodeURIComponent("");
    const shareTextFacebook = encodeURIComponent("");
    let fileNumber = sampleLocation.replace("/","").replace("news","");
    let articleJson : any = null;

    const ArticleRender = () => {
        let jsonFile = new XMLHttpRequest();
        jsonFile.open("GET", `${process.env.PUBLIC_URL}/news/articles/news${fileNumber}.json`, false);        
        jsonFile.onreadystatechange = () => {
            if (jsonFile.readyState === 4) {                
                if (jsonFile.status === 200 || jsonFile.status === 0) {
                    articleJson = JSON.parse(jsonFile.responseText);
                }
            }
        };
        jsonFile.send(null);
        let allFile : any = 0;
        let stateFile = new XMLHttpRequest();
        stateFile.open("GET", `${process.env.PUBLIC_URL}/state_data/news_state.json`, false);        
        stateFile.onreadystatechange = () => {
            if (stateFile.readyState === 4) {                
                if (stateFile.status === 200 || stateFile.status === 0) {
                    allFile = JSON.parse(stateFile.responseText);
                }
            }
        };
        stateFile.send(null);
        let prev = parseInt(sampleLocation.replace("news","").replace("/",""))-1;
        let next = parseInt(sampleLocation.replace("news","").replace("/",""))+1;

        const img = new Image();
        const src = `${process.env.PUBLIC_URL}/news/image/news${fileNumber}.webp`;
        // callback
        img.onload = function(){
            console.log("load complete");
            setImgState("auto");            
        }
        img.src = src;
        const imgError = () => {
            console.log("load error");
            setImgState("none");
        }
        return(
            <>
                <HistoryArea>
                <History to={"/"}>ホーム / </History>
                <History to={sampleLocation} dangerouslySetInnerHTML={{__html:articleJson.title}}></History>
                </HistoryArea>
                <NewsArrange>
                    <SnsWrapper1>
                        <SnslogoLink1 href={"https://line.me/R/share?text="+currentLink} target="_blank">
                            <Snslogo1 src={`${process.env.PUBLIC_URL}/sns/line.webp`}/>
                        </SnslogoLink1>
                        <SnsSpan1/>
                        <SnslogoLink1 href={"https://twitter.com/share?url="+currentLink+"&text="+shareTextTwitter} target="_blank">
                            <Snslogo1 src={`${process.env.PUBLIC_URL}/sns/twitter.webp`}/>
                        </SnslogoLink1>
                        <SnsSpan1/>
                        <SnslogoLink1 href={"https://www.facebook.com/sharer/sharer.php?u="+shareTextFacebook} target="_blank">
                            <Snslogo1 src={`${process.env.PUBLIC_URL}/sns/facebook.webp`}/>
                        </SnslogoLink1>
                        <SnsSpan1/>
                        <SnslogoLink1 href={"http://b.hatena.ne.jp/entry/"+currentLink} target="_blank">
                            <Snslogo1 src={`${process.env.PUBLIC_URL}/sns/hatena.webp`}/>
                        </SnslogoLink1>
                    </SnsWrapper1>
                    <ArticleWrapper>
                        <ArticleTitle dangerouslySetInnerHTML={{__html:articleJson.title}}></ArticleTitle>
                        <UpdateDate dangerouslySetInnerHTML={{__html:articleJson.date}}></UpdateDate>
                        <br/>
                        <ArticleDetail dangerouslySetInnerHTML={{__html:articleJson.content}}>
                        </ArticleDetail>
                        <ArticleImgWrapper>
                            <ArticleImg imgState={imgState} src={`${process.env.PUBLIC_URL}/news/image/news${fileNumber}.webp`} onError={() => {imgError()}}/>
                        </ArticleImgWrapper>
                        <TransitionArea>
                            <Back to={allFile["news"+prev]?"/news"+prev:""} onClick={() => window.scrollTo(0, 0)}>＜ Prev {allFile["news"+prev]?allFile["news"+prev].title:"-"}</Back>
                            <Forward to={allFile["news"+next]?"/news"+next:""} onClick={() => window.scrollTo(0, 0)}>{allFile["news"+next]?allFile["news"+next].title:"-"} Next ＞</Forward>
                        </TransitionArea>
                    </ArticleWrapper>
                    <SnsWrapper2>
                        <SnslogoLink2 href={"https://line.me/R/share?text="+currentLink} target="_blank">
                            <Snslogo2 src={`${process.env.PUBLIC_URL}/sns/line.webp`}/>
                        </SnslogoLink2>
                        <SnslogoLink2 href={"https://twitter.com/share?url="+currentLink+"&text="+shareTextTwitter} target="_blank">
                            <Snslogo2 src={`${process.env.PUBLIC_URL}/sns/twitter.webp`}/>
                        </SnslogoLink2>
                        <SnslogoLink2 href={"https://www.facebook.com/sharer/sharer.php?u="+shareTextFacebook} target="_blank">
                            <Snslogo2 src={`${process.env.PUBLIC_URL}/sns/facebook.webp`}/>
                        </SnslogoLink2>
                        <SnslogoLink2 href={"http://b.hatena.ne.jp/entry/"+currentLink} target="_blank">
                            <Snslogo2 src={`${process.env.PUBLIC_URL}/sns/hatena.webp`}/>
                        </SnslogoLink2>
                    </SnsWrapper2>
                </NewsArrange>
            </>
        )
    }
    
    return(
        <GroundWrapper>
            <ArticleRender/>
        </GroundWrapper>
    )
});