import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CheckMarkImg from "../assets/checkmark.webp"
import BatsuMarkImg from "../assets/batsumark.webp"
import ReactGA from "react-ga4";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics 測定 ID を入力して設定
    ReactGA.initialize("G-S76879P0PF");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;

const GroundWrapper = styled.div`
    width:100%;
    height:100%;
    max-width:1200px;
    margin-left:auto;
    margin-right:auto;
`
const HistoryArea = styled.div`
    height:80px;
`
const History = styled(Link)`
    text-decoration:none;
    @media (prefers-color-scheme: dark) {
        color: #FFF;
    }
    @media (prefers-color-scheme: light) {
        color: #000;
    }
`
const ProductContentsWrapper = styled.div`
    width:80%;
    max-width:930px;
    margin-left:auto;
    margin-right:auto;
    padding:20px;
    background:#d3d3d3;
    color:#000;
`
const ProductArrange = styled.div`
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
    @media (max-width: 375px) {
        flex-flow: column;
        background:#d3d3d3;
    }
`
const SnsWrapper1 = styled.div`
    display: -webkit-flex; /* Safari */
    display:flex;
    flex-flow: column;
    @media (max-width: 375px) {
        display:none;
    }
`
const SnslogoLink1 = styled.a`
    cursor:pointer;
`
const Snslogo1 = styled.img`
    width:50px;
    @media (max-width: 768px) {
        width:45px;
    }
    @media (max-width: 375px) {
        width:35px;
    }
    @media (prefers-color-scheme: dark) {
        background: #FFF;
    }
`
const SnsSpan1 = styled.span`
    height:30px;
    @media (max-width: 768px) {
        height:20px;
    }
    @media (max-width: 375px) {
        height:10px;
    }
`
const SnsWrapper2 = styled.div`
    display:none;
    @media (max-width: 375px) {
        display: -webkit-flex; /* Safari */
        display:flex;
        flex-flow: row;
        -webkit-justify-content: space-around; /* Safari */
        justify-content:space-around;
        background:#d3d3d3;
    }
`
const SnslogoLink2 = styled.a`
    cursor:pointer;
    
`
const Snslogo2 = styled.img`
    width:50px;
    @media (max-width: 768px) {
        width:45px;
    }
    @media (max-width: 375px) {
        width:35px;
    }
`
const ProductTitleWrapper = styled.div`
    width:100%;
    margin-left:auto;
    margin-right:auto;
    <!--background:#fff;-->
`
const ProductTitle = styled.p`
    font-weight:bold;
    font-size:40px;
    text-align:center;
    @media (max-width: 768px) {
        font-size:23px;
    }
`
const ProductExplanationWrapper = styled.div`
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
    @media (max-width: 768px) {
        display: block;
    }
`
const ProductImageWrapper = styled.div`
    width:50%;
    height:100%;
    position:relative;
    @media (max-width: 768px) {
        width:100%;
        text-align:center;
    }
`
const ProductImage = styled.img`
    width:90%;
    max-width:500px;
    top:100px;
    @media (max-width: 768px) {
        position:sticky;
        width:100%;
    }
`
const ProductDetails = styled.div`
    width:50%;
    @media (max-width: 768px) {
        width:100%;
    }
`
const ProductOverViewWrapper = styled.div`
    margin-bottom:10px;
    @media (max-width: 768px) {
        margin-top:10px;
    }
`
const ProductOverView = styled.div`
    <!--background:#fff;-->
`
const ProductEnvWrapper = styled.div`
    margin-bottom:10px;
`
const ProductEnv = styled.div`
<!--background:#fff;-->
`
const ProductPriceWrapper = styled.div`
    margin-bottom:10px;
`
const ProductPrice = styled.div`
<!--background:#fff;-->
`
const SectionSpacer = styled.p`
    width:100%;
    height:60px;
    @media (max-width: 375px) {
        height:10px;
    }
`

const ProductInquiryArea = styled.div`
    display:grid;
    grid-template-columns:25% 25% 25% 25%;
    grid-auto-rows:40px;
    gap:5px;
    max-width:1250px;
    @media (max-width: 768px) {
        grid-template-columns:50% 50%;
    }
`
const ProductInquiryTitle = styled.div`
    grid-column:span 4;
    text-align:center;
    font-weight:bold;
    font-size:20px;
    @media (max-width: 768px) {
        grid-column:span 2;
        font-size:16px;
    }
`
const ProductInquiryInput = styled.input` 
    border:0;
    @media (max-width: 375px) {
        grid-column:span 2;
    }
    ::placeholder{
        font-size:14px;
        font-family:'meiryo',-apple-system, BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen',
            'Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
        @media (max-width: 768px) {
            font-size:12px;
        }
        @media (max-width: 375px) {
            font-size:10px;
        }
    }
`
const ProductInquiryInput2 = styled.input`
    border:0;
    grid-column:span 2;
    ::placeholder{
        font-size:14px;
        font-family:'meiryo',-apple-system, BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen',
            'Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
        @media (max-width: 768px) {
            font-size:12px;
        }
        @media (max-width: 375px) {
            font-size:10px;
        }
    }
`
const ProductInquiryInput3 = styled.textarea`
    grid-column:span 4;
    grid-row:span 6;
    border:0;
    resize: none;
    ::placeholder{
        font-size:14px;
        font-family:'meiryo',-apple-system, BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen',
            'Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
        @media (max-width: 768px) {
            font-size:12px;
        }
        @media (max-width: 375px) {
            font-size:10px;
        }
    }
    @media (max-width: 768px) {
        grid-column:span 2;
    }
`
const InquirySubmitButtonArea = styled.div`
    width:100%;
    text-align:center;
    margin-top:20px;
    margin-bottom:20px;
`
interface submitButtonProps {
    submitButtonColor:string,
    submitButtonPoint:string,
}
const InquirySubmitButton = styled.input<submitButtonProps>`
    width:300px;
    height:50px;
    font-size:20px;
    font-weight:bold;
    color:white;
    background:${(props) => props.submitButtonColor};
    pointer-events:${(props) => props.submitButtonPoint};
    border-radius:10px;
    border:0;
    cursor:pointer;
    @media (max-width: 768px) {
        font-size:20px;
        width:200px;
    }
    @media (max-width: 375px) {
        font-size:16px;
        width:100px;
    }
`
interface postMessageProps {
    postFlag:boolean
}
const AlertCurtain = styled.div<postMessageProps>`
    display:${(props) => props.postFlag?"auto":"none"};
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:299;
    background:#000;
    opacity:0.4;
`
const InquiryAlertArea = styled.div<postMessageProps>`
    display:${(props) => props.postFlag?"auto":"none"};
    top:calc(50% - 110px);
    right:calc(50% - 170px);
    position:fixed;
    width:250px;
    height:220px;
    padding-top:25px;
    padding-bottom:25px;
    padding-right:45px;
    padding-left:45px;
    background:#fff;
    z-index:300;
    border:1px solid black;
    text-align:center;
    border-radius:10px;
    @media (max-width: 768px) {
        top:calc(50% - 100px);
        right:calc(50% - 135px);
        width:200px;
        height:170px;
        padding-top:15px;
        padding-bottom:15px;
        padding-right:35px;
        padding-left:35px;
    }
    @media (max-width: 375px) {
        top:calc(50% - 75px);
        right:calc(50% - 100px);
        width:160px;
        height:120px;
        padding-top:10px;
        padding-bottom:20px;
        padding-right:20px;
        padding-left:20px;
    }
`
const InquiryAlertImg = styled.img`
    width:150px;
    @media (max-width: 768px) {
        width:120px;
    }
    @media (max-width: 375px) {
        width:70px;
    }
`
const InquiryAlert = styled.div`
    width:250px;
    font-weight:bold;
    margin-bottom:20px;
    @media (max-width: 768px) {
        width:200px;
    }
    @media (max-width: 375px) {
        width:160px;
    }
`
const InquiryAlertButton = styled.button`
    width:100px;
    padding:15px;
    font-weight:bold;
    color:white;
    background:#87cefa;
    border-radius:10px;
    border:0;
    cursor:pointer;
    :hover{
        background:#00bfff;
    }
    @media (max-width: 768px) {
        width:100px;
        padding:10px;
    }
    @media (max-width: 375px) {
        width:70px;
        padding:8px;
    }
`
const InquiryErrorArea = styled.div`
    width:100%;
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
    text-align:left;
    margin-bottom:20px;
`
interface errorProps {
    errorFlag:boolean
}
const InquiryErrorImg = styled.img<errorProps>`
    display:${(props) => props.errorFlag?"auto":"none"};
    width:50px;
    margin-bottom:auto;
    margin-top:auto;
    margin-left:10px;
    margin-right:10px;
    @media (max-width: 768px) {
        width:40px;
    }
    @media (max-width: 375px) {
        width:30px;
    }
`
const InquiryError = styled.div`
    margin-bottom:auto;
    margin-top:auto;
`
export const Product = React.memo(() => {

    const currentLink = encodeURIComponent(window.location.href);
    const shareTextTwitter = encodeURIComponent("");
    const shareTextFacebook = encodeURIComponent("");
    // const shareTextHatena = encodeURIComponent("");

    const [imgState,setImgState] = useState<boolean>(false);

    //inqury
    const inquiry_ref_firstname : any = React.createRef();
    const inquiry_ref_lastname : any = React.createRef();
    const inquiry_ref_email : any = React.createRef();
    const inquiry_ref_phone : any = React.createRef();
    const inquiry_ref_companyName : any = React.createRef();
    const inquiry_ref_NsId : any = React.createRef();
    const inquiry_ref_UserNumber : any = React.createRef();
    const inquiry_ref_comment : any = React.createRef();
    const [submitButtonColor,setSubmitButtonColor] : any = useState<string>("#89c3eb");
    const [submitButtonPoint,setSubmitButtonPoint] : any = useState<string>("none");
    const [errorFlag,setErrorFlag] : any = useState<boolean>(false);
    const [errorEmail,setErrorEmail] : any = useState<string>("");
    const [errorPhone,setErrorPhone] : any = useState<string>("");
    const [errorNsId,setErrorNsId] : any = useState<string>("");
    const [errorUserNum,setErrorUserNum] : any = useState<string>("");
    const [postFlag,setPostFlag] : any = useState<boolean>(false);
    const sampleLocation : any = useLocation().pathname;
    let productNumber = sampleLocation.replace("/","").replace("product","");
    productNumber = parseInt(productNumber);
    const checkInputPhoneNum = (input:any) => {
        if (input.currentTarget.value.match(/^[0-9]+$/) || input.currentTarget.value === ""){
            setErrorPhone("");
            console.log("phone");
            if(!errorEmail && !errorNsId && !errorUserNum )setErrorFlag(false);
        }else{
            setErrorFlag(true);
            setErrorPhone("電話番号は半角数字で入力してください。");
            input.focus();
            return;
        }
    }
    const checkInputNsId = (input:any) => {
        if (input.currentTarget.value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/) || input.currentTarget.value === ""){
            setErrorNsId("");
            console.log("nsID");
            if(!errorEmail && !errorUserNum && !errorPhone)setErrorFlag(false);
        }else{
            setErrorFlag(true);
            setErrorNsId("NetSuiteアカウント番号は半角数字で入力してください。");
            input.focus();
            return;
        }
    }
    const checkInputUserName = (input:any) => {
        if (input.currentTarget.value.match(/^[0-9]+$/) || input.currentTarget.value === ""){
            setErrorUserNum("");
            console.log("usname");
            if(!errorEmail && !errorNsId && !errorPhone)setErrorFlag(false);
        }else{
            setErrorFlag(true);
            setErrorUserNum("希望ユーザー数は半角数字で入力してください。");
            input.focus();
            return;
        }
    }
    const checkInputTypeEmail = (input:any) => {
        if (input.currentTarget.value.match(/.+@.+\..+/) || input.currentTarget.value === ""){
            setErrorEmail("");
            console.log("email");
            if(!errorNsId && !errorUserNum && !errorPhone)setErrorFlag(false);
        }else{
            setErrorFlag(true);
            setErrorEmail("メールアドレスを正しい形式で入力してください。");
            input.focus();
            return;
        }
    }

    //20240414 HIRAKI,T reCAPTCHAv3 Add Begin
    // reCAPTCHA v3のサイトキーを定義
    const SITE_KEY = '6LeO8K8pAAAAAMl2M8CwkFYe43qF2oikypaF4uvg';

    // reCAPTCHA v3 APIをページにロード
    const loadReCaptcha = () => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
        script.async = true;
        document.head.appendChild(script);
    };
    //20240414 HIRAKI,T reCAPTCHAv3 Add End

    const inquiryPost = () => {
        grecaptcha.ready(() => {//20240414 HIRAKI,T reCAPTCHAv3 Add
            grecaptcha.execute(SITE_KEY, {action: 'submit'}).then((token: string) => {//20240414 HIRAKI,T reCAPTCHAv3 Add
                let lastName : any = document.getElementById("at_inquiry_lastname");
                let firstName : any = document.getElementById("at_inquiry_firstname");
                let email : any = document.getElementById("at_inquiry_emailaddress");
                let company : any = document.getElementById("at_inquiry_companyname");
                let phoneNumber : any = document.getElementById("at_inquiry_phonenumber");
                let nsAccount : any = document.getElementById("at_inquiry_nsaccountid");
                let userCnt : any = document.getElementById("at_inquiry_usercnt");
                let comment : any = document.getElementById("at_inquiry_comment");
                let emptyCheckArr : any = {
                    "御芳名(姓)":lastName.value,
                    "御芳名(名)":firstName.value,
                    "Email":email.value,
                    "貴社名":company.value,
                    "御電話番号":phoneNumber.value,
                    "その他備考":comment.value
                };
                let errrorMes : any = "";
                for(let key in emptyCheckArr){
                    if(!emptyCheckArr[key]){
                        errrorMes += String(key) + ",";
                    }
                }
                if(errrorMes){
                    let errorStr = errrorMes.substring(0, errrorMes.length - 1);
                    alert(errorStr+"は入力必須項目です。再度入力してから送信してください。");
                    return;
                }

                let formData = new FormData();
                formData.append("lastname",lastName.value);
                formData.append("firstname",firstName.value);
                formData.append("emailaddress",email.value);
                formData.append("companyname",company.value);
                formData.append("phonenumber",phoneNumber.value);
                formData.append("nsaccountid",nsAccount.value);
                formData.append("usercnt",userCnt.value);
                formData.append("comment",comment.value);
                formData.append("applicationid",productNumber);
                formData.append("pagetype","2");
                formData.append("recaptcha_token", token); // トークンをフォームデータに追
                const data = new URLSearchParams();
                formData.forEach((val,key) => {
                    data.append(key,String(val));
                });
                fetch("https://5171452.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=1363&deploy=2&compid=5171452&ns-at=AAEJ7tMQxIoftuBRaG7Dk2-_PFhC01lw_XhSoDMB9oi0VXfRrrE", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: data
                }).then((res) => {
                    alert("送信完了しました。");
                });
                setPostFlag(true);
            });//20240414 HIRAKI,T reCAPTCHAv3 Add
        });//20240414 HIRAKI,T reCAPTCHAv3 Add
    }
    
    //20240414 HIRAKI,T reCAPTCHAv3 Add Begin
    // reCAPTCHAの読み込み
    loadReCaptcha();
    //20240414 HIRAKI,T reCAPTCHAv3 Add End

    const inquiryFillCheck = (e:any) => {
        if(inquiry_ref_firstname.current.value && inquiry_ref_lastname.current.value && inquiry_ref_email.current.value.match(/.+@.+\..+/) &&
         inquiry_ref_companyName.current.value && inquiry_ref_phone.current.value.match(/^[0-9]+$/) && inquiry_ref_comment.current.value &&
         (inquiry_ref_NsId.current.value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/) || inquiry_ref_NsId.current.value === "") && (inquiry_ref_UserNumber.current.value.match(/^[0-9]+$/) || inquiry_ref_UserNumber.current.value === "")){
            setSubmitButtonColor("#1e90ff");
            setSubmitButtonPoint("auto");
        }else{
            setSubmitButtonColor("#89c3eb");
            setSubmitButtonPoint("none");
        }
    }
    const afterPost = () => {
        let lastName : any = document.getElementById("at_inquiry_lastname");
        let firstName : any = document.getElementById("at_inquiry_firstname");
        let email : any = document.getElementById("at_inquiry_emailaddress");
        let company : any = document.getElementById("at_inquiry_companyname");
        let phoneNumber : any = document.getElementById("at_inquiry_phonenumber");
        let nsAccount : any = document.getElementById("at_inquiry_nsaccountid");
        let userCnt : any = document.getElementById("at_inquiry_usercnt");
        let comment : any = document.getElementById("at_inquiry_comment");

        setPostFlag(false);
        setSubmitButtonColor("#89c3eb");
        setSubmitButtonPoint("none");
        setErrorFlag(false);
        setErrorEmail("");
        setErrorPhone("");
        setErrorNsId("");
        setErrorUserNum("");
        lastName.value = "";
        firstName.value = "";
        email.value = "";
        company.value = "";
        phoneNumber.value = "";
        nsAccount.value = "";
        userCnt.value = "";
        comment.value = "";
    }
    let productJson : any = "";
    let jsonFile = new XMLHttpRequest();
    jsonFile.open("GET", `${process.env.PUBLIC_URL}/product/articles/product${productNumber}.json`, false);        
    jsonFile.onreadystatechange = () => {
        if (jsonFile.readyState === 4) {                
            if (jsonFile.status === 200 || jsonFile.status === 0) {
                productJson = JSON.parse(jsonFile.responseText);
            }
        }
    };
    jsonFile.send(null);

    const img = new Image();
    const src = `${process.env.PUBLIC_URL}/product/image/product${productNumber}.webp`;
    img.onload = function(){
        console.log("load complete");
        setImgState(true);
    }
    img.onerror = function() { 
        console.log("load error");
        setImgState(false);
    } 
    img.src = src;
    return(
        <>
            <AlertCurtain postFlag={postFlag}></AlertCurtain>
            <InquiryAlertArea postFlag={postFlag}>
                <InquiryAlertImg src={CheckMarkImg}/>
                <InquiryAlert>お問合せを確かに承りました。</InquiryAlert>
                <InquiryAlertButton onClick={(e) => {afterPost();}}>OK</InquiryAlertButton>
            </InquiryAlertArea>
            <GroundWrapper>
                <HistoryArea>
                    <History to={"/"}>ホーム / </History>
                    <History to={sampleLocation} dangerouslySetInnerHTML={{__html:productJson.title}}></History>
                </HistoryArea>
                <ProductArrange>
                    <SnsWrapper1>
                        <SnslogoLink1 href={"https://line.me/R/share?text="+currentLink} target="_blank">
                            <Snslogo1 src={`${process.env.PUBLIC_URL}/sns/line.webp`}/>
                        </SnslogoLink1>
                        <SnsSpan1/>
                        <SnslogoLink1 href={"https://twitter.com/share?url="+currentLink+"&text="+shareTextTwitter} target="_blank">
                            <Snslogo1 src={`${process.env.PUBLIC_URL}/sns/twitter.webp`}/>
                        </SnslogoLink1>
                        <SnsSpan1/>
                        <SnslogoLink1 href={"https://www.facebook.com/sharer/sharer.php?u="+shareTextFacebook} target="_blank">
                            <Snslogo1 src={`${process.env.PUBLIC_URL}/sns/facebook.webp`}/>
                        </SnslogoLink1>
                        <SnsSpan1/>
                        <SnslogoLink1 href={"http://b.hatena.ne.jp/entry/"+currentLink} target="_blank">
                            <Snslogo1 src={`${process.env.PUBLIC_URL}/sns/hatena.webp`}/>
                        </SnslogoLink1>
                    </SnsWrapper1>
                    <ProductContentsWrapper>
                        <ProductTitleWrapper>
                            <ProductTitle dangerouslySetInnerHTML={{__html:productJson.title}}></ProductTitle>
                        </ProductTitleWrapper>
                        <ProductExplanationWrapper>
                            <ProductImageWrapper>
                                {
                                    imgState === true?<ProductImage src={`${process.env.PUBLIC_URL}/product/image/product${productNumber}.webp`}/>:<ProductImage src={`${process.env.PUBLIC_URL}/common/noimage.webp`}/>
                                }
                            </ProductImageWrapper> 
                            <ProductDetails>
                                <ProductOverViewWrapper>
                                    <ProductOverView>
                                        <b>■アプリケーション概要</b><br/>
                                        <span dangerouslySetInnerHTML={{__html:productJson.overview}}></span>
                                    </ProductOverView>
                                </ProductOverViewWrapper>
                                <ProductEnvWrapper>
                                    <ProductEnv>
                                        <br/><b>■必要な環境</b><br/>
                                        <span dangerouslySetInnerHTML={{__html:productJson.environment}}></span>
                                    </ProductEnv>
                                </ProductEnvWrapper>
                                <ProductPriceWrapper>
                                    <ProductPrice>
                                        <br/><b>■ご利用料金</b><br/>
                                        <span dangerouslySetInnerHTML={{__html:productJson.price}}></span>
                                    </ProductPrice>
                                </ProductPriceWrapper>
                            </ProductDetails>
                        </ProductExplanationWrapper>
                        <InquiryErrorArea>
                        <InquiryErrorImg src={BatsuMarkImg} errorFlag={errorFlag} />
                        <InquiryError>
                            <p style={{margin:"0px"}}>{errorEmail}</p>
                            <p style={{margin:"0px"}}>{errorPhone}</p>
                            <p style={{margin:"0px"}}>{errorNsId}</p>
                            <p style={{margin:"0px"}}>{errorUserNum}</p>
                        </InquiryError>
                        </InquiryErrorArea>
                        <ProductInquiryArea>
                            <ProductInquiryTitle>お問合せ/ご利用申込フォーム</ProductInquiryTitle>
                            <ProductInquiryInput ref={inquiry_ref_firstname} id="at_inquiry_lastname" placeholder="御芳名(姓) *" type="text" onChange={(e) => {inquiryFillCheck(e)}}/>
                            <ProductInquiryInput ref={inquiry_ref_lastname} id="at_inquiry_firstname" placeholder="御芳名(名) *" type="text" onChange={(e) => {inquiryFillCheck(e)}}/>
                            <ProductInquiryInput ref={inquiry_ref_email} id="at_inquiry_emailaddress" placeholder="Email *" type="email" pattern=".+@.+\..+" onChange={(e) => {inquiryFillCheck(e)}} onBlur={checkInputTypeEmail}/>
                            <ProductInquiryInput ref={inquiry_ref_phone} id="at_inquiry_phonenumber" placeholder="御電話番号 *" type="tel" onChange={(e) => {inquiryFillCheck(e);checkInputPhoneNum(e);}}/>
                            <ProductInquiryInput2 ref={inquiry_ref_companyName} id="at_inquiry_companyname" placeholder="貴社名 *" type="text" onChange={(e) => {inquiryFillCheck(e);}}/>
                            <ProductInquiryInput ref={inquiry_ref_NsId} id="at_inquiry_nsaccountid" placeholder="貴社NetSuiteアカウント番号" pattern="[0-9]*" onChange={(e) => {inquiryFillCheck(e);checkInputNsId(e);}}/>
                            <ProductInquiryInput ref={inquiry_ref_UserNumber} id="at_inquiry_usercnt" placeholder="ご希望ユーザ数" pattern="[0-9]*" onChange={(e) => {inquiryFillCheck(e);checkInputUserName(e);}}/>
                            <ProductInquiryInput3 ref={inquiry_ref_comment} id="at_inquiry_comment" placeholder="その他備考 *" onChange={(e) => {inquiryFillCheck(e);}}/>
                        </ProductInquiryArea>
                        <InquirySubmitButtonArea>
                                <InquirySubmitButton submitButtonPoint={submitButtonPoint} submitButtonColor={submitButtonColor} type="submit" value="Submit" onClick={inquiryPost}/>
                        </InquirySubmitButtonArea>
                    </ProductContentsWrapper>
                    <SnsWrapper2>
                        <SnslogoLink2 href={"https://line.me/R/share?text="+currentLink} target="_blank">
                            <Snslogo2 src={`${process.env.PUBLIC_URL}/sns/line.webp`}/>
                        </SnslogoLink2>
                        
                        <SnslogoLink2 href={"https://twitter.com/share?url="+currentLink+"&text="+shareTextTwitter} target="_blank">
                            <Snslogo2 src={`${process.env.PUBLIC_URL}/sns/twitter.webp`}/>
                        </SnslogoLink2>
                        
                        <SnslogoLink2 href={"https://www.facebook.com/sharer/sharer.php?u="+shareTextFacebook} target="_blank">
                            <Snslogo2 src={`${process.env.PUBLIC_URL}/sns/facebook.webp`}/>
                        </SnslogoLink2>
                        
                        <SnslogoLink2 href={"http://b.hatena.ne.jp/entry/"+currentLink} target="_blank">
                            <Snslogo2 src={`${process.env.PUBLIC_URL}/sns/hatena.webp`}/>
                        </SnslogoLink2>
                    </SnsWrapper2>
                </ProductArrange>
                <SectionSpacer/>
            </GroundWrapper>
        </>
    )
});
