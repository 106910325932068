import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
import { useEffect, useLayoutEffect } from "react";
import ReactGA from "react-ga4";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics 測定 ID を入力して設定
    ReactGA.initialize("G-S76879P0PF");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;


const GroundWrapper = styled.div`
    width:100%;
    height:100%;
    max-width:1200px;
    margin-left:auto;
    margin-right:auto;
`
const HistoryArea = styled.div`
    height:70px;
    @media (max-width: 768px) {
        height:50px;
    }
    @media (max-width: 375px) {
        height:30px;
    }
`
const History = styled(Link)`
    text-decoration:none;
    @media (prefers-color-scheme: dark) {
        color: #FFF;
    }
    @media (prefers-color-scheme: light) {
        color: #000;
    }
`

const GridArea = styled.div`
    width:80%;
    display:grid;
    grid-template-columns:35% 35% 30%;
    grid-auto-rows:40px;
    gap:20px;
    margin-left:auto;
    margin-right:auto;
    @media (max-width: 768px) {
        gap:10px;
    }
`
const GridTitle = styled.p`
    font-weight:bold;
    font-size:18px;
    @media (max-width: 768px) {
        font-size:17px;
    }
    @media (max-width: 375px) {
        font-size:14px;
    }
`
const GridCell = styled.p`
`
const HeadLine = styled.p`
    font-weight:bold;
    font-size:25px;
    text-align:center;
    @media (max-width: 768px) {
        font-size:20px;
    }
    @media (max-width: 375px) {
        font-size:17px;
    }
`
const HeadLineDetail = styled.p`
    text-align:center;
`
const ArticleWrapper = styled.div`
    width:80%;
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
    margin-left:auto;
    margin-right:auto;
    @media (max-width: 375px) {
        width:100%;
    }
`
const ArticleImg = styled.img`
    width:60%;
`
const ArticleDetail1 = styled.p`
    width:40%;
    margin:0; 
    margin-left:30px;
    text-align:right;
    font-size:20px;
    font-weight: bold;
`
const ArticleDetail2 = styled.p`
    width:40%;
    margin:0; 
    margin-right:30px;
    font-size:20px;
    font-weight: bold;
`
const Spacer = styled.div`
    height:40px;
    width:100%;
`
const LinkTo = styled(Link)`
    @media (prefers-color-scheme: dark) {
        color: #FFF;
    }
    @media (prefers-color-scheme: light) {
        color: #000;
    }
`

export const Recruit =  () => {
    let occupationList : any = null;
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });
    
    let rawFile = new XMLHttpRequest();
    rawFile.open("GET", `${process.env.PUBLIC_URL}/state_data/recruit_state.json`, false);        
    rawFile.onreadystatechange = () => {
        if (rawFile.readyState === 4) {                
            if (rawFile.status === 200 || rawFile.status === 0) {
                occupationList = JSON.parse(rawFile.responseText);
            }
        }
    };
    rawFile.send(null);
    const OccupationGrid = () => {
        let rows : any = [];
        for(let i = 0; i < occupationList.length; i++){
            rows.push(<GridCell key={"name"+i}><LinkTo to={"/"+occupationList[i].url}>{occupationList[i].name}</LinkTo></GridCell>);
            rows.push(<GridCell key={"location"+i}>{occupationList[i].location}</GridCell>);
            rows.push(<GridCell key={"number"+i}>{occupationList[i].number}</GridCell>);
        }
        return rows;
    }
    return(
        <GroundWrapper>
            <HistoryArea>
                <History to={"/"}>ホーム / </History>
                <History to={"/recruit"}>採用情報</History>
            </HistoryArea>
            <GridArea>
                <GridTitle>募集職種</GridTitle>
                <GridTitle>勤務地</GridTitle>
                <GridTitle>募集人数</GridTitle>
                <OccupationGrid/>
            </GridArea>
            <HeadLine>「プロフェッショナル人材を創出し社会貢献を行う」</HeadLine>
            <HeadLineDetail>
                現在、SuiteUPでは業績好調とさらなる成長のため、
                積極的に人材を育成しています。
            </HeadLineDetail>
            <ArticleWrapper>
                <ArticleImg src={`${process.env.PUBLIC_URL}/recruit/recruit0.webp`}/>
                <ArticleDetail1>
                    <br/><br/>
                    君たちの可能性が、<br/>
                    次の可能性を<br/>
                    生み出す。<br/>
                </ArticleDetail1>
            </ArticleWrapper>
            <br/><br/>
            <ArticleWrapper>
                <ArticleDetail2>
                    <br/><br/>
                    挑戦しよう。<br/>
                    挑戦のなかに、<br/>
                    君だけの答えが生まれる。<br/>
                </ArticleDetail2>
                <ArticleImg src={`${process.env.PUBLIC_URL}/recruit/recruit1.webp`}/>
            </ArticleWrapper>
            <Spacer/>
        </GroundWrapper>
    )
}