import { useEffect } from 'react';
import './App.css';
import styled from "styled-components";
import { Route, Routes, HashRouter, useLocation } from "react-router-dom";
import { Home } from "./pages/Home";
import { Product } from "./pages/Product";
import { Case } from "./pages/Case";
import { News } from "./pages/News";
import { Recruit } from "./pages/Recruit";
import { CompanyOverview } from "./pages/CompanyOverview";
import { RecruitDetail } from "./pages/RecruitDetail";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

const GroundWrapper = styled.div`
    width:100%;
    height:100%;
    max-width:2300px;
    margin-left:auto;
    margin-right:auto;
`
export const App = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])
  
    return null;
  }
  let productList : any = [];
  let newsList : any = [];
  let instanceList : any = [];
  let recruitList : any = [];
  
  let productFile = new XMLHttpRequest();
  productFile.open("GET", `${process.env.PUBLIC_URL}/state_data/product_state.json`, false);        
  productFile.onreadystatechange = () => {
    if (productFile.readyState === 4) {                
      if (productFile.status === 200 || productFile.status === 0) {
        productList = JSON.parse(productFile.responseText);
      }
    }
  };
  productFile.send(null);

  let newsFile = new XMLHttpRequest();
  newsFile.open("GET", `${process.env.PUBLIC_URL}/state_data/news_state.json`, false);        
  newsFile.onreadystatechange = () => {
    if (newsFile.readyState === 4) {                
      if (newsFile.status === 200 || newsFile.status === 0) {
        let arr : any = [];
        arr = JSON.parse(newsFile.responseText);
        for (let i = 0; i < Object.keys(arr).length; i++){
          newsList.push({url:Object.keys(arr)[i]});
        }
      }
    }
  };
  newsFile.send(null);

  let caseFile = new XMLHttpRequest();
  caseFile.open("GET", `${process.env.PUBLIC_URL}/state_data/case_state.json`, false);        
  caseFile.onreadystatechange = () => {
    if (caseFile.readyState === 4) {                
      if (caseFile.status === 200 || caseFile.status === 0) {
        instanceList = JSON.parse(caseFile.responseText);
      }
    }
  };
  caseFile.send(null);

  let recruitFile = new XMLHttpRequest();
  recruitFile.open("GET", `${process.env.PUBLIC_URL}/state_data/recruit_state.json`, false);        
  recruitFile.onreadystatechange = () => {
    if (recruitFile.readyState === 4) {                
      if (recruitFile.status === 200 || recruitFile.status === 0) {
        recruitList = JSON.parse(recruitFile.responseText);
      }
    }
  };
  recruitFile.send(null);

  return (
    <HashRouter>
        <Header/>
          <GroundWrapper>
          <ScrollToTop/>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/product" element={<Product/>} />
            <Route path="/recruit" element={<Recruit/>} />
            <Route path="/companyoverview" element={<CompanyOverview/>} />
            {
              recruitList.map((token : any,key : any) => {
                  return(
                    <Route key={key} path={"/"+token.url} element={<RecruitDetail/>} />
                  )
              })
            }            
            {
              newsList.map((token : any,key : any) => {
                return(
                  <Route key={key} path={"/"+token.url} element={<News/>}/>
                )
              })
            }
            {
              productList.map((token : any,key : any) => {
                return(
                  <Route key={key} path={"/"+token.url} element={<Product/>}/>
                )
              })
            }
            {
              instanceList.map((token : any,key : any) => {
                return(
                  <Route key={key} path={"/"+token.url} element={<Case/>} />
                )
              })
            }
            </Routes>    
          </GroundWrapper>
        <Footer/>
      </HashRouter>
  );
}
