import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"
import React from "react";
import { useEffect , useState, useLayoutEffect} from "react";
import ReactGA from "react-ga4";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics 測定 ID を入力して設定
    ReactGA.initialize("G-S76879P0PF");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;

const CompanyImgElem = styled.img`
    width:100%;
    max-width:2000px;
`
const GroundWrapper = styled.div`
    width:100%;
    height:100%;
    max-width:1200px;
    margin-left:auto;
    margin-right:auto;
`
const HistoryArea = styled.div`
    height:70px;
    @media (max-width: 768px) {
        height:50px;
    }
    @media (max-width: 375px) {
        height:30px;
    }
`
const History = styled(Link)`
    text-decoration:none;
    @media (prefers-color-scheme: dark) {
        color: #FFF;
    }
    @media (prefers-color-scheme: light) {
        color: #000;
    }
`
const ArticleTitle = styled.p`
    font-weight:bold;
    font-size:27px;
    text-align:center;
    @media (max-width: 768px) {
        font-size:20px;
    }
    @media (max-width: 375px) {
        font-size:17px;
    }
`
const CompanyOverviewList = styled.div`
    width:80%;
    display:grid;
    grid-template-columns:20% 30% 5% 8% 37%;
    grid-auto-rows:45px;
    margin-left:auto;
    margin-right:auto;
    row-gap:20px;
    @media (max-width: 768px) {
        grid-auto-rows:35px;
    }
    @media (max-width: 375px) {
        grid-auto-rows:30px;
        row-gap:20px;
        width:100%;
    }
    @media (max-width: 303px) {
        grid-auto-rows:45px;
    }
`
const GridCell = styled.div`
    height:100%;
    @media (prefers-color-scheme: dark) {
        border-bottom:1px solid #FFF;
    }
    @media (prefers-color-scheme: light) {
        border-bottom:1px solid #000;
    }
`
const MissionWrapper = styled.div`
    width:80%;
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: space-around; /* Safari */
    justify-content:space-around;
    margin-left:auto;
    margin-right:auto;
    @media (max-width: 768px) {
        display: block;
    }
`
const MissionContainer = styled.div`
    @media (max-width: 768px) {
        text-align:center;
    }
`
const MissionImgElem = styled.img`
    width:250px;
    @media (max-width: 375px) {
        width:150px;
    }
`
const MissionMessage = styled.div`
    width:200px;
    margin-top:20px;
    margin-bottom:20px;
    margin-right:auto;
    margin-left:auto;
    text-align: center;
    @media (max-width: 768px) {
        margin-top:15px;
        margin-bottom:15px;
        font-weight: bold;

    }
    @media (max-width: 375px) {
        width:80%;
        margin-top:10px;
        margin-bottom:10px;
        font-weight: bold;
    }
`
const MissionDetail = styled.div`
    width:250px;
    margin-right:auto;
    margin-left:auto;
    @media (max-width: 375px) {
        width:80%;
    }
`

const ProfileWrapper = styled.div`
    width:80%;
    margin-left:auto;
    margin-right:auto;
`
const ProfileImgWrapper = styled.div`
    text-align:center;
`
const ProfileImgElem = styled.img`
    width:50%;
    @media (max-width: 768px) {
        width:60%;
    }
    @media (max-width: 375px) {
        width:80%;
    }
`
const PresidentMessage = styled.div`
`
const PresidentNameWrapper = styled.div`
    text-align:right;
`
const PresidentName = styled.img`
    width:300px;
    @media (max-width: 768px) {
        width:200px;
    }
    @media (max-width: 375px) {
        width:150px;
    }
`
const PresidentLinkedIn = styled.div`
    text-align:right;
    *{
        @media (prefers-color-scheme: dark) {
            color: #FFF;
        }
        @media (prefers-color-scheme: light) {
            color: #000;
        }
    }
`
export const CompanyOverview = React.memo(() => {
    useLayoutEffect(() => {
        window.scrollTo(0,0);
    },[]);

    let articlesList : any;
    let stateFile = new XMLHttpRequest();
        stateFile.open("GET", `${process.env.PUBLIC_URL}/companyoverview/companyoverview.json`, false);        
        stateFile.onreadystatechange = () => {
            if (stateFile.readyState === 4) {                
                if (stateFile.status === 200 || stateFile.status === 0) {
                    articlesList = JSON.parse(stateFile.responseText);
                }
            }
        };
        stateFile.send(null);
    return(
        <>
            <HistoryArea>
                <History to={"/"}>ホーム / </History>
                <History to={"/companyoverview"}>会社概要</History>
            </HistoryArea>
            <CompanyImgElem src={`${process.env.PUBLIC_URL}/companyoverview/companyoverview_top.webp`}/>
            <GroundWrapper>
                <ArticleTitle>会社概要</ArticleTitle>
                <CompanyOverviewList>
                    <GridCell>会社名</GridCell>
                    <GridCell>株式会社SuiteUP(スイートアップ)</GridCell>
                    <GridCell style={{borderBottom:"0"}}></GridCell>
                    <GridCell>設立</GridCell>
                    <GridCell>2018年05月08日 (創業 2017年05月27日)</GridCell>
                    <GridCell>資本金</GridCell>
                    <GridCell style={{gridColumn:"span 4"}}>980万円</GridCell>
                    <GridCell>PJ体制</GridCell>
                    <GridCell style={{gridColumn:"span 4"}}>2024年4月現在 31名体制（協力会社、派遣社員含む）</GridCell>
                    <GridCell>グローバル本社</GridCell>
                    <GridCell style={{gridColumn:"span 4"}}>〒101-0025 東京都千代田区神田佐久間町1-8-4 アルテール秋葉原708 (<a href="https://www.google.com/maps/place/%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BESuiteUP/@35.6972974,139.7709023,17z/data=!3m2!4b1!5s0x60188ea85e3b2ab3:0xe2df8ce84147b5cd!4m6!3m5!1s0x60188fdb3c4b5093:0xa44a67bf538415a8!8m2!3d35.6972974!4d139.7734772!16s%2Fg%2F11f5_jg1ck?entry=ttu" target="_new">map</a>)</GridCell>
                    <GridCell>大阪オフィス</GridCell>
                    <GridCell style={{gridColumn:"span 4"}}>〒542-0076 大阪府大阪市中央区難波5-1-60 Namba SkyO 27F (<a href="https://www.google.com/maps?sca_esv=d479c9d490b2d7ef&sca_upv=1&output=search&q=%E3%81%AA%E3%82%93%E3%81%B0%E3%82%B9%E3%82%AB%E3%82%A4%E3%82%AA&source=lnms&entry=mc&ved=1t:200715&ictx=111" target="_new">map</a>)</GridCell>
                    <GridCell>その他拠点</GridCell>
                    <GridCell style={{gridColumn:"span 4"}}>熊本開発室(熊本県熊本市中央区九品寺)</GridCell>
                    {/*<GridCell>顧問弁護士</GridCell>
                    <GridCell style={{gridColumn:"span 4"}}>森田法律事務所　森田 智宏</GridCell>*/}
                </CompanyOverviewList>
                <ArticleTitle>ミッション</ArticleTitle>
                <MissionWrapper>
                    <MissionContainer>
                        <MissionImgElem src={`${process.env.PUBLIC_URL}/companyoverview/mission0.webp`}/>
                        <MissionMessage dangerouslySetInnerHTML={{__html:articlesList.mission1_message}}></MissionMessage>
                        <MissionDetail dangerouslySetInnerHTML={{__html:articlesList.mission1_detail}}></MissionDetail>
                    </MissionContainer>
                    <MissionContainer>
                        <MissionImgElem src={`${process.env.PUBLIC_URL}/companyoverview/mission1.webp`}/>
                        <MissionMessage dangerouslySetInnerHTML={{__html:articlesList.mission2_message}}></MissionMessage>
                        <MissionDetail dangerouslySetInnerHTML={{__html:articlesList.mission2_detail}}></MissionDetail>
                    </MissionContainer>
                    <MissionContainer>
                        <MissionImgElem src={`${process.env.PUBLIC_URL}/companyoverview/mission2.webp`}/>
                        <MissionMessage dangerouslySetInnerHTML={{__html:articlesList.mission3_message}}></MissionMessage>
                        <MissionDetail dangerouslySetInnerHTML={{__html:articlesList.mission3_detail}}></MissionDetail>
                    </MissionContainer>
                </MissionWrapper>
                <ArticleTitle>代表取締役挨拶</ArticleTitle>
                <ProfileWrapper>
                    <ProfileImgWrapper>
                        <ProfileImgElem src={`${process.env.PUBLIC_URL}/companyoverview/companyoverview_profile.webp`}/>
                    </ProfileImgWrapper>
                    <PresidentMessage dangerouslySetInnerHTML={{__html:articlesList.president_message}}></PresidentMessage>
                    <PresidentNameWrapper>
                        <PresidentName src={`${process.env.PUBLIC_URL}/companyoverview/companyoverview_sign.webp`}/>
                    </PresidentNameWrapper>
                    <PresidentLinkedIn dangerouslySetInnerHTML={{__html:articlesList.linkedin}}></PresidentLinkedIn>
                </ProfileWrapper>
            </GroundWrapper>
        </>
    )
});
