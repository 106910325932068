import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics 測定 ID を入力して設定
    ReactGA.initialize("G-S76879P0PF");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;

const GroundWrapper = styled.div`
    width:100%;
    height:100%;
    max-width:1200px;
    margin-left:auto;
    margin-right:auto;
`
const HistoryArea = styled.div`
    height:70px;
    @media (max-width: 768px) {
        height:50px;
    }
    @media (max-width: 375px) {
        height:30px;
    }
`
const History = styled(Link)`
    text-decoration:none;
    @media (prefers-color-scheme: dark) {
        color: #FFF;
    }
    @media (prefers-color-scheme: light) {
        color: #000;
    }
`

const TopImgWrapper = styled.div`
    position:relative;
`
const TopImgElem = styled.img`
    width:100%;
`
const TopLine1 = styled.p`
    color:white;
    font-size:50px;
    font-weight:bold;
    position:absolute;
    bottom:200px;
    text-stroke:1px #000;
    -webkit-text-stroke: 1px #000;
    @media (max-width: 768px) {
        font-size:30px;
        bottom:70px;
    }
    @media (max-width: 375px) {
        font-size:20px;
        bottom:50px;
    }
`
const TopLine2 = styled.p`
    color:white;
    font-size:30px;
    font-weight:bold;
    position:absolute;
    bottom:150px;    
    text-stroke:1px #000;
    -webkit-text-stroke: 1px #000;
    @media (max-width: 768px) {
        font-size:20px;
        bottom:40px;
    }
    @media (max-width: 375px) {
        font-size:15px;
        bottom:30px;
    }
`
const TopLine3 = styled.p`
    color:white;
    font-size:30px;
    font-weight:bold;
    position:absolute;
    bottom:100px;
    text-stroke:1px #000;
    -webkit-text-stroke: 1px #000;
    @media (max-width: 768px) {
        font-size:20px;
        bottom:10px;
    }
    @media (max-width: 375px) {
        font-size:13px;
    }
`
const ArticleWrapper = styled.div`
    max-width:1000px;
    width:80%;
    margin-left:auto;
    margin-right:auto;
`
const ArticleExplanation = styled.div`
    width:100%;
    margin-bottom:40px;
`
const ArticleTitle = styled.p`
    font-size:30px;
    font-weight:bold;
    text-align:center;
    @media (max-width: 768px) {
        font-size:20px;
    }
    @media (max-width: 375px) {
        font-size:15px;
    }
`

const CompanyHeadLine = styled.p`
    font-size:15px;
    font-weight:bold;
`
const CompanyInfoWrapper = styled.div`
    display:grid;
    grid-template-columns:20% 80%;
    grid-auto-rows:40px;
    background:silver;
    margin-bottom:40px;
    color: #000;
`
const CompanyInfoCell = styled.p`

`
const ArticleImg = styled.img`
    width:100%;
`

export const Case = React.memo(() => {
    const [imgState1,setImgState1] = useState<boolean>(false);
    const [imgState2,setImgState2] = useState<boolean>(false);
    const [imgState3,setImgState3] = useState<boolean>(false);
    const [imgState4,setImgState4] = useState<boolean>(false);
    const imgStateArr = [setImgState1,setImgState2,setImgState3,setImgState4];
    const sampleLocation : any = useLocation().pathname;
    let fileNumber = sampleLocation.replace("/","").replace("case","");
    let caseArticles : any = {};
    let rawFile = new XMLHttpRequest();
    rawFile.open("GET", `${process.env.PUBLIC_URL}/case/case${fileNumber}.json`, false);        
    rawFile.onreadystatechange = () => {
        if (rawFile.readyState === 4) {                
            if (rawFile.status === 200 || rawFile.status === 0) {
                caseArticles = JSON.parse(rawFile.responseText);
            }
        }
    };
    rawFile.send(null);
    for(let i = 0; i < 4; i++){        
        const img = new Image();
        const src = `${process.env.PUBLIC_URL}/case/image/case${fileNumber}_${i+1}.webp`;
        img.onload = function(){
            console.log("load complete");
            imgStateArr[i](true);
        }
        img.onerror = function() { 
            console.log("load error");
            imgStateArr[i](false);
        } 
        img.src = src;
    }
    return(
        <GroundWrapper>
            <HistoryArea>
                <History to={"/"}>ホーム / 事例紹介 / </History>
                <History to={sampleLocation} dangerouslySetInnerHTML={{__html:caseArticles.companyname}}></History>
            </HistoryArea>
            <TopImgWrapper>
                <TopImgElem src={`${process.env.PUBLIC_URL}/case/image/case${fileNumber}_top.webp`}/>
                <TopLine1 dangerouslySetInnerHTML={{__html:caseArticles.topline}}></TopLine1>
                <TopLine2 dangerouslySetInnerHTML={{__html:caseArticles.companyname}}></TopLine2>
                <TopLine3 dangerouslySetInnerHTML={{__html:caseArticles.presidentname}}></TopLine3>
            </TopImgWrapper>
            <ArticleWrapper>
                <ArticleExplanation dangerouslySetInnerHTML={{__html:caseArticles.about}}></ArticleExplanation>
                <ArticleTitle>事業内容</ArticleTitle>
                <ArticleExplanation dangerouslySetInnerHTML={{__html:caseArticles.business}}></ArticleExplanation>
                <ArticleExplanation>
                    {
                        imgState1 === true?<ArticleImg src={`${process.env.PUBLIC_URL}/case/image/case${fileNumber}_1.webp`}/>:<></>
                    }
                </ArticleExplanation>
                <ArticleTitle><ArticleExplanation dangerouslySetInnerHTML={{__html:caseArticles.application_before}}></ArticleExplanation></ArticleTitle>
                <ArticleExplanation dangerouslySetInnerHTML={{__html:caseArticles.before}}></ArticleExplanation>
                <ArticleExplanation>
                    {
                        imgState2 === true?<ArticleImg src={`${process.env.PUBLIC_URL}/case/image/case${fileNumber}_2.webp`}/>:<></>
                    }
                </ArticleExplanation>
                <ArticleTitle><ArticleExplanation dangerouslySetInnerHTML={{__html:caseArticles.application_before}}></ArticleExplanation></ArticleTitle>
                <ArticleExplanation dangerouslySetInnerHTML={{__html:caseArticles.after}}></ArticleExplanation>
                <ArticleExplanation>
                    {
                        imgState3 === true?<ArticleImg src={`${process.env.PUBLIC_URL}/case/image/case${fileNumber}_3.webp`}/>:<></>
                    }
                </ArticleExplanation>
                <ArticleTitle>今後</ArticleTitle>
                <ArticleExplanation dangerouslySetInnerHTML={{__html:caseArticles.plan}}></ArticleExplanation>
                <ArticleExplanation>
                    {
                        imgState4 === true?<ArticleImg src={`${process.env.PUBLIC_URL}/case/image/case${fileNumber}_4.webp`}/>:<></>
                    }
                </ArticleExplanation>
                <CompanyHeadLine>会社情報</CompanyHeadLine>
                <CompanyInfoWrapper>
                    <CompanyInfoCell>会社名</CompanyInfoCell>
                    <CompanyInfoCell dangerouslySetInnerHTML={{__html:caseArticles.companyname}}></CompanyInfoCell>
                    <CompanyInfoCell>WebSite</CompanyInfoCell>
                    <CompanyInfoCell dangerouslySetInnerHTML={{__html:caseArticles.website}}></CompanyInfoCell>
                    <CompanyInfoCell>住所</CompanyInfoCell>
                    <CompanyInfoCell dangerouslySetInnerHTML={{__html:caseArticles.address}}></CompanyInfoCell>
                    <CompanyInfoCell>ご連絡先</CompanyInfoCell>
                    <CompanyInfoCell dangerouslySetInnerHTML={{__html:caseArticles.email}}></CompanyInfoCell>
                    <CompanyInfoCell>業種</CompanyInfoCell>
                    <CompanyInfoCell dangerouslySetInnerHTML={{__html:caseArticles.field}}></CompanyInfoCell>
                    <CompanyInfoCell>売上高</CompanyInfoCell>
                    <CompanyInfoCell dangerouslySetInnerHTML={{__html:caseArticles.sales}}></CompanyInfoCell>
                    <CompanyInfoCell>資本金</CompanyInfoCell>
                    <CompanyInfoCell dangerouslySetInnerHTML={{__html:caseArticles.capital}}></CompanyInfoCell>
                    <CompanyInfoCell>従業員数</CompanyInfoCell>
                    <CompanyInfoCell dangerouslySetInnerHTML={{__html:caseArticles.employeenumber}}></CompanyInfoCell>
                </CompanyInfoWrapper>
            </ArticleWrapper>
        </GroundWrapper>
    )
});